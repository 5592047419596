@import"https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap";

:root {
    --primary: #48a1ff;
    --white: #ffffff;
    --focused: #40af21;
    --lines: #8B8E98;
    --fontColor: #d3d3d3;
  }

.modal {
    width: fit-content;
    height: fit-content;
    background: #063a0b;
    box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 26px;
    max-width: 450px;
    scale: 150%;
    border: 5px solid #d3d3d3;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    color: var(--fontColor);
  }
  
  .payment--options {
    max-width: calc(100%);
    display: grid;
    grid-template-columns: 33% 34% 33%;
    padding: 10px;
    overflow: hidden;
  }

  .payment--options button {
    height: 60px;
    width: 90%;
    border-radius: 11px;
    padding: 2px;
    margin-left: auto;
    margin-right: auto;
    background-color: #F2F2F2;
    border: 1px transparent;
    outline: none;
    color: #3980b9;
    transition: box-shadow 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  }

  .clicked {
    padding: 0;
    box-shadow: 0px 0px 0px 2px var(--focused);
    background-color: transparent;
    color: #295c85;
    font-weight: 600; 
  }
  
  .logo {
    position: relative;
    top: 30%;
    transform: translateY(-50%);
    padding: 5px 10px;
    max-width: 50px;
    max-height: 50px;
  }

  .logo-text {
    position: relative;
    bottom: 10%;
  }

  .payment--options button:last-child svg {
    height: 22px;
  }
  
  .separator {
    /* width: calc(100% - 20px); */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    color: var(--fontColor);
    margin: 0 0px;
  }
  
  .separator > p {
    word-break: keep-all;
    display: block;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    margin: auto;
  }
  
  .separator .line {
    display: inline-block;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: var(--lines);
    margin: auto;
  }

  .line {
    display: inline-block;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: var(--lines);
    margin: 10px;
    align-self: center;
  }

  .credit-card-info--form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .info--form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .input_container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  
  .split {
    display: grid;
    grid-template-columns: 4fr 2fr;
    gap: 15px;
  }
  
  .split input {
    width: 100%;
  }
  
  .input_label {
    font-size: 12px;
    color: var(--fontColor);
    font-weight: 600;
    margin-bottom: -5px;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .input_field {
    flex-grow: 1;
    height: 40px;
    padding: 0 0 0 16px;
    margin-right: 5px;
    border-radius: 9px;
    outline: none;
    background-color: #297e26;
    border: 1px solid transparent;
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
    font-family: 'Roboto';
    /* font-size: 13px; */
    color: white;
    caret-color: var(--white);

}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--white);
  opacity: 1; /* Firefox */
}

.input_field:focus {
    border: 1px solid var(--focused);
    box-shadow: 0px 0px 0px 2px var(--focused);
    background-color: transparent;
  }

  .input_icon {
    max-height: 40px;
    max-width: 40px;
    min-height: 40px;
    min-width: 40px;
  }
  
  .buy--btn {
    height: 40px;
    background: #F2F2F2;
    border-radius: 10px;
    border: 0;
    outline: none;
    color: #ffffff;
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 700;
    background: var(--focused);
    box-shadow: 0px 0px 0px 0px #FFFFFF, 0px 0px 0px 0px #000000;
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  }
  
  .buy--btn:hover {
    box-shadow: 0px 0px 0px 2px grey, 0px 0px 0px 4px #0000003a;
    
  }
  
  /* Reset input number styles */
  .input_field::-webkit-outer-spin-button,
  .input_field::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .input_field[type=number] {
    -moz-appearance: textfield;
  }

  .connect-button {
    display: flex;
    justify-content: left;
    align-items: left;
    width: 100%;
  }

  .wallet-balance-label {
    font-size: 15px;
    color: var(--fontColor);
    font-weight: 600;
    align-self: center;
    padding-left: 10px;
    font-family: 'Roboto';
    margin-bottom: 0px;
  }

  button:disabled {
    opacity: .3;
  }